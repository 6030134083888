<template>
  <div class="wrapper">
    <router-view/>
  </div>
</template>

<script setup>
import {onMounted} from "vue";
import {encryptColor} from "@/utils/encryptColor";
import {replace} from "@/utils/symbolsReplacement";

onMounted(()=>{
})
</script>

<style lang="stylus">
@import './styles/helpers.styl';
</style>

<style>

*, *:before, *:after {
  box-sizing: border-box;
}


body {
  /*background: black;*/
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

.bubble{
  font-family:'Press Start 2P';
  font-size: 16px;
  line-height:1.3em;
}


</style>
