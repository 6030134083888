export function replace(text) {
    let replacedText = '';

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        if (replacementMap.hasOwnProperty(char)) {
            replacedText += replacementMap[char];
        } else {
            replacedText += char;
        }
    }

    return replacedText;
}

const replacementMap = {
    1: 'Z',
    2: 'X',
    3: 'C',
    4: 'V',
    5: 'B',
    6: 'N',
    7: 'M',
    8: 'A',
    9: 'S',
    0: 'D',
    'Z': 1,
    'X': 2,
    'C': 3,
    'V': 4,
    'B': 5,
    'N': 6,
    'M': 7,
    'A': 8,
    'S': 9,
    'D': 0,
};
